import React from "react";
import notfoundsvg from "../assets/img/404.svg";
import { PiKeyReturnFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="min-w-screen min-h-screen bg-real-color flex items-center p-5 lg:p-20 overflow-hidden relative">
        <div className="flex-1 min-h-full min-w-full rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
          <div className="w-full md:w-1/2">
            <div className="mb-10 lg:mb-20"></div>
            <div className="mb-10 md:mb-20 text-gray-600 font-light">
              <h1 className="font-black uppercase text-3xl lg:text-5xl text-real-color mb-10">
                Parece que está perdido!
              </h1>
              <div className=" text-default-real">
                <p>A página que tentou aceder não está disponivel.</p>
                <p>Tente procurar novanete, ou clique no botão para voltar.</p>
              </div>
            </div>
            <div className="mb-20 md:flex md:items-center">
              <button className="text-lg font-light outline-none focus:outline-none transform transition-all hover:scale-110 flex items-center text-white hover:text-gray-50 bg-real-color rounded-xl p-2"
                onClick={() => navigate(-1)}
              >
                <PiKeyReturnFill className="mr-2" size={20} />
                Voltar
              </button>
            </div>
          </div>
          <div className="w-full md:w-1/2 text-center">
            <img src={notfoundsvg} alt="404" />
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
