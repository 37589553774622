import React, { useState } from "react";
import { sendFormDataToBackend } from "../api/sendForm";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    color: "",
  });

  const [termos, AceitaTermos] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    // valida se está tudo definido e se checkbox está true
    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.subject === "" ||
      formData.message === "" ||
      termos === false
    ) {
      alert(
        "Por favor preencha todos os campos e aceite a política de privacidade"
      );
      return;
    }
    e.preventDefault();

    // if color as value ignore, its a bot sending spam
    if (formData.color !== "") {
      return;
    }

    const responseData = await sendFormDataToBackend(formData);
    if (responseData as any) {
      alert("Mensagem enviada com sucesso");
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
        color: "",
      });
    } else {
      alert("Ocorreu um erro ao enviar a mensagem");
    }
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Introduza o seu nome"
            className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500 text-blue-600"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Introduza o seu Email"
            className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500 text-blue-600 "
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            placeholder="Introduza o assunto"
            className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500 text-blue-600 "
            required
          />
        </div>
        <div className="mb-4">
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Introduza a sua mensagem"
            rows={4}
            className="border border-gray-300 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500 text-blue-600 resize-none"
            required
          />
        </div>
        {/* privacy polacy text and checkbox */}
        <div className="mb-4 flex">
          <label className="inline-flex justify-start">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-600"
              onChange={(e) => AceitaTermos(e.target.checked)}
              required
            />
            <span className="ml-2 text-gray-700 text-justify text-sm">
              Li e compreendi a{" "}
              <a
                href="https://www.realvidaseguros.pt/images/stories/pdf/politica_de_privacidade.pdf"
                target="#_blank"
                className="text-real-color underline"
              >
                Política de Privacidade
              </a>{" "}
              da Real Vida Seguros e, nessa sequência, presto o consentimento
              para se proceder ao tratamento dos meus dados pessoais.
            </span>
          </label>
        </div>

        {/* favourite color must be input hidden and empty */}
        <input
          type="hidden"
          name="color"
          value={formData.color}
          onChange={handleChange}
        />

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Enviar
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
