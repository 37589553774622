import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchPlantasDataById } from "../api/search";

const PlantPreview = () => {
  const { id } = useParams();
  const [planta, setPlanta] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedPlanta = await fetchPlantasDataById(id);
        setPlanta(fetchedPlanta as any);
      } catch (error) {
        console.error("Error fetching planta data:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!planta) {
    return <div>Loading...</div>;
  }

  const { title, area, planta_file_url, componente } = planta;
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-800">
      <h1 className="text-white text-3xl font-bold mb-4">{title}</h1>
      <p className="text-white text-xl">{componente}</p>
      <p className="text-white text-xl mt-2">{area}</p>
      {planta_file_url && (
        <a
          href={planta_file_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline mt-4"
        >
          VER PDF
        </a>
      )}

      {/* botao para fechar o browser */}
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
        onClick={() => window.history.back()}
      >
        Fechar
      </button>
    </div>
  );
};

export default PlantPreview;
