
const Backoffice = () => {
  

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-semibold mb-6">Backoffice</h1>
        <div className="flex flex-col gap-4">
          
          <a
            href="https://strapi.realbusinesscenter.wt.pt"
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline text-center"
          >
            Strapi
          </a>
        </div>
      </div>
    </div>
  );
};

export default Backoffice;
