import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fetchEdificiosData } from "../../api/search";
import GalleryImage from "../GalleryImage";

interface BuildingItem {
  id: number;
  title: string;
  componente: string;
  imageUrls: string[];
}


function ItemsConhecaMobile() {

  const [Items,setItems] = useState<BuildingItem[]>([]);

  // fetchedificiosdata
  useEffect(() => {
    fetchEdificiosData().then((data) => {
      setItems(data);
    });
  }, []);


  const hasImages = (item: BuildingItem): boolean => {
    if (item.imageUrls.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  
  return (
    <div>
      {Items.map((item: any, index: any) => {
        return (
          <div
            key={item.id}
            className={`flex flex-col mt-4 md:flex-row-reverse md:justify-between ${
              index % 2 === 0 ? "flex-row-reverse" : "" // Inverts the order of elements in even rows
            }`}
          >
            <div className="w-full p-4">
              <div className="text-subtitles-of-real text-left mb-6 text-default-real">
                <p className="font-bold">{item.title}</p>
              </div>
              <div className="flex justify-center">
                {hasImages(item) ? (
                  <div className="w-full">
                    {/* Coloque aqui o código para exibir a imagem */}
                    <div className="flex">
                      {/* check for the ammount of images and if more then 1 load as gallery */}
                      {item.imageUrls.length > 1 ? (
                        <GalleryImage image={item.imageUrls} />
                      ) : (
                        <LazyLoadImage
                          src={item.imageUrls[0]}
                          alt={item.title}
                          className="w-full"
                        />
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="w-full p-4">
              <div className="text-default-light text-xl text-justify">
                {/* componente is html */}
                <div dangerouslySetInnerHTML={{ __html: item.componente }} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ItemsConhecaMobile;
