import { useEffect, useState } from "react";
import { RiFileDownloadLine } from "react-icons/ri";


import { fetchPlantasData } from "../api/search";

interface PlantasItem {
  id: number;
  title: string;
  componente: string;
  planta_file_url: string;
  area: string;
}

const PlantasSection = () => {
  const [plantasItems, setPlantasItems] = useState<PlantasItem[]>([]);

  // try fetch plantas
  useEffect(() => {
    fetchPlantasData().then((data) => {
      setPlantasItems(data);
      
    });
  }, []);

  return (
    <div id="plantas" className="mt-48 flex w-full">
      <div className="w-full h-full plantas p-4">
        <div className="flex w-full mt-12">
          <div className="w-full h-full">
            <div className="flex flex-col items-center justify-center w-full h-full">
              <h1 className="text-white text-titles-real uppercase tracking-wide">
                Plantas
              </h1>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-5 gap-4 mt-4 font-bold">
          {plantasItems.map((item) => (
            <div key={item.id} className="flex flex-col items-center p-12">
              <div className="flex items-center mb-4">
                <p className="font-bold text-white text-titles-real mr-4 justify-center text-center">
                  {item.title}
                </p>
                {/* if there is pdfurl show icon with href */}
                {item.planta_file_url && (
                  <div className="flex items-center">
                    <a href={item.planta_file_url} target="_blank" rel="noreferrer">
                      <RiFileDownloadLine className="text-white font-bold text-titles-real md:w-8 md:h-8 h-12 w-12 cursor-pointer" />
                    </a>
                  </div>
                )}
              </div>
              <div className="flex flex-col text-xl text-justify tracking-normal">
                <p className="text-white mt-2 overflow-auto text-xl">
                  {item.componente}
                </p>
                {/* Area (below the component) */}
              </div>
              <div className="flex flex-col text-xl text-justify tracking-normal">
                <p className="text-white mt-2 ">{item.area}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlantasSection;
