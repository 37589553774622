import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchEdificiosDataById } from "../api/search";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GalleryImage from "../components/GalleryImage";

interface BuildingItem {
  id: number;
  title: string;
  componente: string;
  imageUrls: string[];
}

const OfficePreview = () => {
  const { id } = useParams();
  const [edificio, setEdificio] = useState<BuildingItem | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchEdificiosDataById(id);
        setEdificio(data as any);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [id]);
  // items as imageUrls is an array, if the array is empty then set hasimage to false, if is not empty set to true, altough it should check for the array length, in case of the array being only 1 length should load only the image, if it's bigger then should load the gallery

  const hasImages = (item: BuildingItem): boolean => {
    if (item.imageUrls.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {edificio && (
        <>
          <div className="flex justify-between mt-4 ">
            <div className="flex justify-center">
              {hasImages(edificio) ? (
                <div className="w-full p-12">
                  <div className="flex">
                    {edificio.imageUrls.length > 1 ? (
                      <GalleryImage image={edificio.imageUrls} />
                    ) : (
                      <LazyLoadImage
                        src={edificio.imageUrls[0]}
                        alt={edificio.title}
                        className="w-full"
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="w-full p-12">
                  <LazyLoadImage
                    src={edificio.imageUrls[0]}
                    alt={edificio.title}
                    className="w-full"
                  />
                </div>
              )}
            </div>
            <div className="w-1/2 p-12">
              <h1 className="text-4xl font-bold">{edificio.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: edificio.componente,
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* botao para voltar */}
      {!edificio || !hasImages(edificio) ? null : (
        <div className="absolute bottom-0 left-0 p-4">
          <button
          // close separador
            onClick={() => window.close()}
            className="bg-gray-800 text-white px-4 py-2 rounded"
          >
            Voltar
          </button>
        </div>
      )}

    </div>
  );
};

export default OfficePreview;
