import { LazyLoadImage } from "react-lazy-load-image-component";

import footerimage from "../../assets/img/Sketch_Vista_Viseu_.webp";
function Footer(){
    return(
        <div className="flex w-full">
        <div className="w-full h-1/2">
          <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden">
            <LazyLoadImage
              alt="Footer"
              className="w-full"
              effect="blur"
              src={footerimage}
            />
          </div>
        </div>
      </div>
    )
};

export default Footer;