import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fetchEdificiosData } from "../api/search";
import GalleryImage from "./GalleryImage";
interface BuildingItem {
  id: number;
  title: string;
  componente: string;
  imageUrls: string[];
}


function ItemsConheca() {
  
  const [items, setItems] = useState<BuildingItem[]>([]);

  useEffect(() => {
    fetchEdificiosData().then((data) => {
      setItems(data);
    });
  }, []);


  const hasImages = (item: BuildingItem): boolean => {
    if (item.imageUrls.length === 0) {
      return false;
    } else {
      return true;
    }
  };


  return (
    <div>
      {items.map((item: any, index: any) => {
        return (
          <div
            key={item.id}
            className={`flex justify-between mt-4 ${
              index % 2 === 0 ? "flex-row-reverse" : ""
            }`}
          >
            {hasImages(item) ? (
              <div className="w-1/2 p-12">
                {/* Coloque aqui o código para exibir a imagem */}
                <div className="flex">
                  {/* check for the ammount of images and if more then 1 load as gallery */}
                  {item.imageUrls.length > 1 ? (
                    <GalleryImage image={item.imageUrls} />
                  ) : (
                    <LazyLoadImage
                      src={item.imageUrls[0]}
                      alt={item.title}
                      className="w-full"
                    />
                  )}

                </div>
              </div>
            ) : null}

            <div
              className={`w-1/2 p-12 ${
                hasImages(item) ? "" : "lg:w-full" // If no image, set to full width
              }`}
            >
              <div className="text-subtitles-of-real text-left mb-6 text-default-real">
                <p className="font-bold">{item.title}</p>
              </div>
              <div className=" text-default-light text-xl text-justify tracking-normal">
                {/* componente is html */}
                <div dangerouslySetInnerHTML={{ __html: item.componente }} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ItemsConheca;
