import React, { useEffect, useRef, useState } from "react";
import "../assets/css/gallery.css";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import poster from "../assets/conceito/1.webp";
import realVidaVideo from "../assets/video/RVS_Imobiliario_RBC.mp4";
import scroll_gif from "../assets/img/scroll_01.gif";

import { Link, scroller } from "react-scroll";
import useDetectScroll, { Direction } from "@smakss/react-scroll-direction";

import { isMobile } from "react-device-detect";

function VideoLoader({ active }: any) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [showCaret, setShowCaret] = useState(true);
  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const scrollDir = useDetectScroll({});
  const linkContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const goto = () => {
      // timeout
      setTimeout(() => {
        scroller.scrollTo("espaco_esritorios", {
          duration: 1000,
          smooth: true,
          offset: 20, // Scrolls to element + 20 pixels down the page
        });
      }, 750);
    };

    const getDirection = (direction: any) => {
      switch (direction) {
        case Direction.Still:
          return "Still";
        case Direction.Up:
          return "Up";
        case Direction.Down:
          goto();
          return "Down";
        default:
          return "Still";
      }
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        setShowCaret(entry.isIntersecting);
        if (entry.isIntersecting) {
          // Se o componente voltar a ser visível, retome a reprodução do vídeo
          if (videoRef.current && isVideoPlaying) {
            videoRef.current.play();
          }
          if (videoRef.current) {
            getDirection(scrollDir);
          }
        }
      });
    };

    const videoObserver = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    if (videoContainerRef.current) {
      videoObserver.observe(videoContainerRef.current);
    }

    return () => {
      videoObserver.disconnect(); // Use 'disconnect' em vez de 'unobserve'
    };
  }, [isVideoPlaying, scrollDir]);

  const handleVideoPause = () => {
    setIsVideoPlaying(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setIsPaused(!isPaused);
  };

  const handleScrollToText = () => {
    handleVideoPause();
  };

  return (
    <div className="teste">
      {active ? (
        <>
          <LazyLoadComponent delayTime={2000}>
            <div className="relative h-full w-full " id="video_div">
              <div ref={videoContainerRef} style={{ position: "relative" }}>
                {!isMobile ? (
                  <video
                    ref={videoRef}
                    width={"100%"}
                    height={"100%"}
                    poster={poster}
                    playsInline
                    muted
                    autoPlay
                    loop
                    autoFocus
                    controlsList="nodownload noremoteplayback nofullscreen"
                    title="Real business center"
                  >
                    <source src={realVidaVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <video
                    ref={videoRef}
                    width={"100%"}
                    height={"100%"}
                    poster={poster}
                    playsInline
                    muted
                    autoPlay
                    loop
                    controlsList="nodownload noremoteplayback"
                    title="Real business center"
                    controls
                  >
                    <source src={realVidaVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                {active && showCaret && !isMobile && (
                  <div
                    className="absolute bottom-12 left-1/2 transform -translate-x-1/2 z-50 flex gap-2"
                    onClick={handleScrollToText}
                    ref={linkContainerRef}
                  >
                    <div className="block rounded-2xl">
                      <Link
                        activeClass="active"
                        to="espaco_esritorios"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={1250}
                      >
                        <img
                          src={scroll_gif}
                          alt="scroll"
                          className="h-24 w-24"
                        />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </LazyLoadComponent>
        </>
      ) : null}
    </div>
  );
}

export default VideoLoader;
