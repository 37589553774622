import React from "react";
import comercial from "../assets/img/office.svg";
import svgForSecondSquare from "../assets/img/shop.svg";
import svgForThirdSquare from "../assets/img/lounge.svg";
import svgForFourthSquare from "../assets/img/multi.svg";

const TableComponent = () => {
  return (
    <div className="w-full h-full text-real-color mt-12 mb-12">
      <div className="flex justify-between">
        {/* Primeira Célula */}
        <div className="flex items-center p-4 shadow-md flex-1 justify-center">
          <div className="w-32 h-32 mr-8">
            <img
              src={comercial}
              alt=""
              width={"100%"}
              height={"auto"}
              className="object-contain"
            />
          </div>
          <div className="flex flex-col">
            <div className="text-real-color text-titles-real uppercase tracking-wide text-center">
              <p className="justify-center text-center">37</p>
            </div>
            <div className="text-subtitles-of-real text-center font-bold">
              <p>Escritórios</p>
            </div>
          </div>
        </div>

        {/* Segunda Célula */}
        <div className="flex items-center p-4 shadow-md flex-1 justify-center">
          <div className="w-32 h-32 mr-8">
            <img
              src={svgForSecondSquare}
              alt=""
              width={"100%"}
              height={"auto"}
              className="object-contain"
            />
          </div>
          <div className="flex flex-col">
            <div className="text-real-color text-titles-real uppercase tracking-wide">
              <p className="justify-center text-center">4</p>
            </div>
            <div className="text-subtitles-of-real text-center font-bold">
              <span>
                Espaços <br /> Comerciais
              </span>
            </div>
          </div>
        </div>

        {/* Terceira Célula */}
        <div className="flex items-center p-4 shadow-md flex-1 justify-center">
          <div className="w-32 h-32 mr-8">
            <img
              src={svgForThirdSquare}
              alt=""
              width={"100%"}
              height={"auto"}
              className="object-contain"
            />
          </div>
          <div className="flex flex-col">
            <div className="text-real-color text-titles-real tracking-wide">
              <p className="justify-center text-center">200m²</p>
            </div>
            <div className="text-subtitles-of-real text-center font-bold">
              <p>
                Área <br /> Lounge
              </p>
            </div>
          </div>
        </div>

        {/* Quarta Célula */}
        <div className="flex items-center p-4 shadow-md flex-1 justify-center">
          <div className="w-32 h-32 mr-8">
            <img
              src={svgForFourthSquare}
              alt=""
              width={"100%"}
              height={"auto"}
              className="object-contain"
            />
          </div>
          <div className="flex flex-col">
            <div className="text-real-color text-titles-real uppercase tracking-wide">
              <p className="justify-center text-center">1</p>
            </div>
            <div className="text-subtitles-of-real text-center font-bold">
              <p>
                Espaço <br /> Multiusos
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
