import { LazyLoadImage } from "react-lazy-load-image-component";
import footerimage from "../../assets/img/Sketch_Vista_Viseu_.webp";

function MobileFooter(){
return(
    <div className="flex w-full mt-12">
        <div className="w-full h-1/2">
            <div className="flex flex-col items-center justify-center w-full h-full overflow-hidden">
                <LazyLoadImage
                alt="Footer"
                effect="blur"
                src={footerimage}
                className="w-full h-full object-none"
                />
            </div>
        </div>
    </div>
)
};

export default MobileFooter;