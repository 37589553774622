import React, { useEffect, useState } from "react";
import MapaGoogle from "../googlemap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fetchLocalizacaoData } from "../../api/search";
import GalleryImage from "../GalleryImage";
// localizacaodata
// id: data.id,
// title1: attributes.title1,
// texto1: attributes.texto1,
// title2: attributes.title2,
// texto2: attributes.texto2,
// title3: attributes.title3,
// texto3: attributes.texto3,
// title4: attributes.title4,
// texto4: attributes.texto4,
// imageUrls: imageUrls

interface local {
  id: string;
  title1: string;
  texto1: any;
  title2: string;
  texto2: any;
  title3: string;
  texto3: any;
  title4: string;
  texto4: any;
  imageUrls: string[];
}
function Localizacao() {
  const [items, setItems] = useState<local>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchLocalizacaoData().then((data) => {
      setItems(data as any);
      setLoading(false);
    });
  }, []);

  const hasImages = (items: local): boolean => {
    if (items.imageUrls.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="flex w-full mt-12">
        <div className="w-full h-full">
          <div className="flex flex-col items-center justify-center w-full h-full">
            <h1 className="text-real-color text-titles-real uppercase tracking-wide">
              Localização
            </h1>
          </div>
        </div>
      </div>

      <div className="flex w-full mt-12 relative">
        <div className="w-1/2 flex flex-col p-12">
          <div className="mt-12 w-full text-left">
            <p className="font-bold text-default-real text-left text-subtitles-of-real mb-6">
              {items?.title1}
            </p>
            <div className="text-default-light text-xl tracking-normal">
              <div dangerouslySetInnerHTML={{ __html: items?.texto1 }} />
            </div>
          </div>

          <div className="mt-12 w-full text-left">
            <p className="font-bold text-default-real text-left text-subtitles-of-real mb-6">
              {items?.title2}
            </p>
            <div className="text-default-light text-xl tracking-normal">
              <div dangerouslySetInnerHTML={{ __html: items?.texto2 }} />
            </div>
          </div>
        </div>
        <div className="w-1/2 m-4 flex relative h-full">
          <div className="flex flex-col items-center justify-center w-full h-full">
            {items ? (
              <>
                {hasImages(items) ? (
                  <div>
                    {items.imageUrls.length > 1 ? (
                      <GalleryImage image={items?.imageUrls} />
                    ) : (
                      <LazyLoadImage
                        alt="Real Business Center"
                        effect="blur"
                        src={items?.imageUrls[0]}
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                ) : null}
              </>
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <div className="w-12 h-12 border-t-2 border-b-2 border-real-color rounded-full animate-spin"></div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex w-full h-full mt-12 relative">
        <div className="w-1/2 m-4 flex relative">
          <MapaGoogle />
        </div>

        <div className="w-1/2 flex flex-col m-4">
          <div className="w-full p-4">
            <div className="w-full text-left">
              <p className="font-bold text-default-real text-left text-subtitles-of-real mb-6">
                {items?.title3}
              </p>
              <div className="text-default-light text-xl tracking-normal">
                <div dangerouslySetInnerHTML={{ __html: items?.texto3 }} />
              </div>
            </div>

            <div className="mt-12 w-full text-left">
              <p className="font-bold text-default-real text-left text-subtitles-of-real mb-6">
                {items?.title4}
              </p>
              <div className="text-default-light text-xl tracking-normal">
                <div dangerouslySetInnerHTML={{ __html: items?.texto4 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Localizacao;
