import { Routes, Route } from "react-router-dom";
import App from "./App";
import NotFound from "./components/notfound";

import Backoffice from "./components/backoffice";

import PlantPreview from "./previews/Planta";
import OfficePreview from "./previews/Escritorio";
import NewsPage from "./components/newComponents/NewsPage";



function Main() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/backoffice" element={<Backoffice />} />
      <Route path="/preview/plantas/:id" element={<PlantPreview />} />
      <Route path="/preview/escritorios/:id" element={<OfficePreview />} />
      <Route path="/noticias" element={<NewsPage />} />
    </Routes>
  );
}

export default Main;
