import React, { useEffect, useState } from "react";
import NewsArticle from "./NewsArticle"; // Importar o componente de renderização de notícias
import Header from "./header";
import Footer from "./Footer";
import { isMobile } from "react-device-detect";
import HeaderMobile from "../Mobile/HeaderMobile";
import { fetchNoticiasData } from "../../api/search";
import MobileNewsArticle from "../../Mobile/components/MobileNewsArticle";
interface News {
  title: string;
  date: string;
  content: string;
  imageUrls: string[];
  link:string;
}

function NewsPage() {
  // detect screensize

  const [newsData,setNewsData] = useState<[]>([]);

  useEffect (() => {
    fetchNoticiasData().then((data) =>{
        setNewsData(data as any);
    });
    },[]);


  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const maxWidth = screenSize > 1920 ? "1900px" : "100%";
  const centerContentClass = screenSize > 1920 ? "mx-auto text-center" : "";

  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh", // Alterado de height para minHeight
        width: "100vw",
        letterSpacing: "normal",
      }}
    >
      <div>
        <div className="font-nexa w-full h-full max-h-full max-w-full overflow-x-hidden">
          <div>{isMobile ? <HeaderMobile /> : <Header />}</div>

          <div
            className={`flex flex-col items-center w-full ${centerContentClass}`}
            style={{
              maxWidth,
              paddingTop: "10rem", // Adicione um espaçamento no topo
            }}
          >
            <div className="flex flex-col items-center w-full">
              <h1 className="text-real-color text-titles-real uppercase tracking-wide">
                Notícias
              </h1>
            </div>
            <div className="flex flex-col items-center w-full">
              {newsData.map((news, index) => (
                <div className="mt-4 p-4" key={index}>
                  {isMobile ? (
                    <MobileNewsArticle key={index} news={news} />
                  ) : (
                    <NewsArticle key={index} news={news} />
                  )}
                </div>
              ))}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
export default NewsPage;
