import { useEffect, useState } from "react";
import ContactForm from "../../components/formulario";
import MapaGoogle, { MobileGoogleMap } from "../../components/googlemap";
import { fetchLocalizacaoData } from "../../api/search";
import GalleryImage from "../../components/GalleryImage";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface local {
  id: string;
  title1: string;
  texto1: any;
  title2: string;
  texto2: any;
  title3: string;
  texto3: any;
  title4: string;
  texto4: any;
  imageUrls: string[];
}
function MobileLocalizacao() {
  const [items, setItems] = useState<local>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchLocalizacaoData().then((data) => {
      setItems(data as any);
      setLoading(false);
    });
  }, []);

  const hasImages = (items: local): boolean => {
    if (items.imageUrls.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div id="localizacao" className="mt-48">
      <div className="flex w-full mt-12">
        <div className="w-full h-full">
          <div className="flex flex-col items-center justify-center w-full h-full">
            <h1 className="text-center text-real-color text-titles-real font-bold">
              Localização
            </h1>
          </div>
        </div>
      </div>

      <div className="flex w-full mt-12 relative">
        <div className="w-full flex flex-col">
          <div className="mt-12 w-full text-left">
            <p className="font-bold text-default-real text-left text-subtitles-of-real mb-6">
              {items?.title1}
            </p>
            <div className="text-default-light text-xl tracking-normal">
              <div dangerouslySetInnerHTML={{ __html: items?.texto1 }} />
            </div>
          </div>

          <div className="mt-12 w-full text-left">
            <p className="font-bold text-default-real text-left text-subtitles-of-real mb-6">
              {items?.title2}
            </p>
            <div className="text-default-light text-xl tracking-normal">
              <div dangerouslySetInnerHTML={{ __html: items?.texto2 }} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex relative h-full">
        <div className="flex flex-col items-center justify-center w-full h-full">
          {items ? (
            <>
              {hasImages(items) ? (
                <div>
                  {items.imageUrls.length > 1 ? (
                    <GalleryImage image={items?.imageUrls} />
                  ) : (
                    <LazyLoadImage
                      alt="Real Business Center"
                      effect="blur"
                      src={items?.imageUrls[0]}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
              ) : null}
            </>
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <div className="w-12 h-12 border-t-2 border-b-2 border-real-color rounded-full animate-spin"></div>
            </div>
          )}
        </div>
      </div>

      <div className="flex w-full mt-12 relative">
        <div className="w-full flex relative">
          <MobileGoogleMap />
        </div>
      </div>

      <div className="flex flex-col mt-12 w-full">
        <div className="w-full flex flex-col">
          <div className="mt-12 w-full text-left">
            <p className="font-bold text-default-real text-left text-subtitles-of-real mb-6">
              {items?.title3}
            </p>
            <div className="text-default-light text-xl tracking-normal">
              <div dangerouslySetInnerHTML={{ __html: items?.texto3}} />
            </div>
          </div>

          <div className="mt-12 w-full text-left">
            <p className="font-bold text-default-real text-left text-subtitles-of-real mb-6">
              {items?.title4}
            </p>
            <div className="text-default-light text-xl tracking-normal">
              <div dangerouslySetInnerHTML={{ __html: items?.texto4 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileLocalizacao;
