import axios from "axios";

// set API_URL and token
const API_URL = "https://strapi.realbusinesscenter.wt.pt/api";

const token =
  "9d7cc675568eb0b0075b97bde01de19f476d82e45b9ef49c0c5a0ac8246c884e76910262046379c5d7c399884a7d1c386d7d25d475928428476cc74d552e96414637be1c36cf7274bf6c6c1fa34e451a3a4614c638baa9a0a2763d04858af2d527d1223f4e4da8a3332d15caa7ffc73fb5cea5c0514e2e7744792ed41184f7b1";

const toEmail = process.env.REACT_APP_TO_EMAIL;
const SendFrom = process.env.REACT_APP_FROM_EMAIL;

export async function sendFormDataToBackend(data) {
  // data to send must be from, FromName, subject, html, but recieves name,email,subject,message. must put message inside html
  const html_template = `
  <!DOCTYPE html>
<html>
<body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f5f5f5;">
<div class="main-container" style="max-width: 600px; margin: 0 auto; margin-top: 30px;">
  <div class="container" style="background-color: #ffffff; padding: 20px; border-radius: 10px; box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); margin-bottom: 20px; margin-top: 20px;">

  <svg width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M12 11.5v5M12 7.51l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <br>
    <div class="icon" style="width: 120px; height: 20px; margin-right: 10px; vertical-align: middle; fill: #000000; display: inline-flex; align-items: center;">
   <p>Boa tarde, </p>
      <?xml version='1.0' encoding='UTF-8'?>
    </div>
    <p>Foi enviado um email através do formulário do Website <a href="https://realbusinesscenter.pt">https://realbusinesscenter.pt</a> com a seguinte mensagem:</p>
  </div>
  <div class="message-box-all" style="background-color: #656565; padding: 2px;">
  <div class="message-box" style="background-color: #f5f5f5; padding: 20px; border-radius: 10px; margin: 10px;">
    <div class="icon" style="width: 24px; height: 24px; margin-right: 10px; vertical-align: middle; fill: #000000; display: inline-flex; align-items: center;">
      <?xml version='1.0' encoding='UTF-8'?><svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><g clip-path="url(#send-diagonal_svg__clip0_2476_13290)" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M22.152 3.553L11.178 21.004l-1.67-8.596L2 7.898l20.152-4.345zM9.456 12.444l12.696-8.89"/></g><defs><clipPath id="send-diagonal_svg__clip0_2476_13290"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
    </div>
    <p><strong>Assunto:</strong>${data?.subject}</p>
    <p><strong>De:</strong> ${data?.name} &lt;<a href="mailto:${data?.email}">${data?.email}</a>&gt;</p>
    <div class="icon" style="width: 24px; height: 24px; margin-right: 10px; vertical-align: middle; fill: #000000; display: inline-flex; align-items: center;">
      <?xml version='1.0' encoding='UTF-8'?><svg width="24px" height="24px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M3 20.29V5a2 2 0 012-2h14a2 2 0 012 2v10a2 2 0 01-2 2H7.961a2 2 0 00-1.561.75l-2.331 2.914A.6.6 0 013 20.29z" stroke="#000000" stroke-width="1.5"/><path d="M10.5 10h-2a1 1 0 01-1-1V8a1 1 0 011 1h1a1 1 0 011 1v2zm0 0c0 1-1 2-2 3M16.5 10h-2a1 1 0 01-1-1V8a1 1 0 011 1h1a1 1 0 011 1v2zm0 0c0 1-1 2-2 3" stroke="#000000" stroke-width="1.5" stroke-linecap="round"/></svg>
    </div>
    <p><strong>Mensagem:</strong></p>
    <div class="message-box-message" style="background-color: #ffff; padding: 10px; border-radius: 10px;">
    <p>${data?.message}</p>
    </div>
  </div>
  </div>
  <div class="container" style="background-color: #ffffff; padding: 20px; border-radius: 10px; box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); margin-bottom: 20px; margin-top: 20px;">
    <p>Caso queira responder, responda a este email.</p>
    <p>Com os melhores cumprimentos,<br>A equipa RBC.</p>
  </div>
</div>
</div></body>
</html>
`;

  const emailData = new FormData();
  emailData.append(
    "to",
    toEmail || "Filipe Braga - <filipe.braga@realvidaseguros.pt>"
  );
  emailData.append("replyTo", `${data?.name} <${data?.email}>`);
  emailData.append(
    "subject",
    `Contacto através do WebSite: "${data?.subject}"`
  );
  emailData.append("html", html_template);
  // make a for loop to append all the data to the form data and log
  for (var pair of emailData.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }

  const requestData = {
    data: {
      fromEmail: data?.email,
      fromName: data?.name,
      subject: data?.subject,
      message: data?.message,
      toEmail: toEmail || "Filipe Braga - <filipe.braga@realvidaseguros.pt>",
      sendFromEmail: SendFrom || "No-Reply <no-reply@realbusinesscenter.pt>",
    },
  };

  try {
    const response = await axios.post(`${API_URL}/forms`, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // if response and response.status === 200 send email
    if (response && response.status === 200) {
      // response -> data -> data -> attributes -> uuid_simulador
      const emailResponse = await axios.post(`${API_URL}/email`, emailData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return emailResponse;
    }
  } catch (error) {
    console.log(error);
  }
}
