import "react-lazy-load-image-component/src/effects/blur.css";
import ItemsConhecaMobile from "../components/Mobile/ItemsMobile";
import PlantasSection from "../components/Mobile/plantasSectionMobile";
import MobileTabela from "./components/MobileTabela";
import MobileConceito from "./components/MobileConceito";
import MobileLocalizacao from "./components/MobileLocalizacao";
import MobileFooter from "./components/MobileFooter";
import MobileContactForm from "./components/MobileContactForm";

function Mobile() {

  return (
    <div className="p-6">
      <MobileTabela />
      <MobileConceito />
      <div className="w-full h-full">
        <div id="espaco" className="mt-48">
          <div className="flex w-full mt-12">
            <div className="w-full h-full">
              <div className="flex flex-col items-center justify-center w-full h-full">
                <h1 className=" text-real-color text-titles-real font-bold uppercase">
                  Conheça o Real Business Center
                </h1>
              </div>
            </div>
          </div>
          <ItemsConhecaMobile />
        </div>

        {/* div plantas */}
        <PlantasSection />
        <MobileLocalizacao />
        <MobileContactForm />
        <MobileFooter />
        
      </div>
    </div>
  );
}

export default Mobile;
