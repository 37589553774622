import React, { useEffect, useState } from "react";

function MapaGoogle() {
  const [googleloading, setGoogleLoading] = useState(false)
  useEffect(() => {
    // make loading screen for iframe
    setGoogleLoading(true)
    const timer = setTimeout(() => {
      setGoogleLoading(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  


  return (
    <div className="w-full relative p-4">
      {googleloading && (
        <div className="w-full h-full absolute bg-white z-50">
          <div className="w-full h-full flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        </div>
      )}

            <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12109.923408277982!2d-7.9091967!3d40.6413296!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2337f45ed9a021%3A0xbaaccca8fdc32aff!2sReal%20Business%20Center%20-%20Viseu!5e0!3m2!1sen!2spt!4v1689767409817!5m2!1sen!2spt"
        width={'100%'}
        height={'100%'}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
}

export default MapaGoogle;



function MobileGoogleMap(){
  const [googleloading, setGoogleLoading] = useState(false)
  useEffect(() => {
    // make loading screen for iframe
    setGoogleLoading(true)
    const timer = setTimeout(() => {
      setGoogleLoading(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  


  return (
    <div className="w-full relative">
      {googleloading && (
        <div className="w-full h-full absolute bg-white z-50">
          <div className="w-full h-full flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        </div>
      )}

            <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12109.923408277982!2d-7.9091967!3d40.6413296!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2337f45ed9a021%3A0xbaaccca8fdc32aff!2sReal%20Business%20Center%20-%20Viseu!5e0!3m2!1sen!2spt!4v1689767409817!5m2!1sen!2spt"
        width={'100%'}
        height={'100%'}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
}

export {MobileGoogleMap}