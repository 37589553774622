import { RiFileDownloadLine } from "react-icons/ri";
import { useEffect, useState } from "react";

import { fetchPlantasData } from "../../api/search";

interface PlantasItem {
  id: number;
  title: string;
  componente: string;
  planta_file_url: string;
  area: string;
}

function PlantasSection() {
  const [plantasItems, setPlantasItems] = useState<PlantasItem[]>([]);

  // try fetch plantas
  useEffect(() => {
    fetchPlantasData().then((data: any) => {
      setPlantasItems(data);
    });
  }, []);

  return (
    <div id="plantas" className="plantas mt-12 p-4">
      <div className="w-full h-full p-4">
        <div className="flex w-full mt-8 justify-center">
          {/* Center the title */}
          <h1 className="text-white text-titles-real font-bold">Plantas</h1>
        </div>
      </div>
      {/* Loop through the items */}
      {plantasItems.map((item: PlantasItem, index: number) => (
        <div
          key={item.id}
          className={`flex flex-col md:flex-row md:justify-between mt-6 ${
            index % 2 === 0 ? "flex-row-reverse" : ""
          }`}
        >
          <div className="w-full p-4 justify-center items-center">
            <div className="flex flex-col items-center md:items-start">
              <p className="text-white text-titles-real">{item.title}</p>
              {item.planta_file_url && (
                <a
                  href={item.planta_file_url}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="flex items-center justify-center text-white text-2xl mt-2"
                >
                  <RiFileDownloadLine />
                </a>
              )}
              <p className="text-white text-xl leading-tight mt-2">
                {item.componente}
              </p>
              <p className="text-white text-subtitles-of-real leading-tight mt-2">
                {item.area}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PlantasSection;
