import React from "react";
import { useNavigate } from "react-router-dom";
import GalleryImage from "../GalleryImage";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface News {
  title: string;
  date: string;
  content: string;
  imageUrls: string[];
  link: string;
}
interface Props {
  news: News;
}

const NewsArticle: React.FC<Props> = ({ news }) => {
  const navigate = useNavigate();

  const hasImages = (item: News): boolean => {
    return item.imageUrls.length > 0;
  };

  const shareNews = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: news.title,
          text: news.date,
          url: news.link,
        });
        console.log("Notícia compartilhada com sucesso!");
      } else {
        console.log("API de compartilhamento não suportada neste navegador.");
      }
    } catch (error) {
      console.error("Erro ao compartilhar notícia:", error);
    }
  };

  return (
    <div className="bg-white p-6 rounded-md shadow-md flex">
      <div className="w-1/2 pr-8">
        {hasImages(news) ? (
          <div className="flex">
            {/* check for the amount of images and if more than 1, load as gallery */}
            {news.imageUrls.length > 1 ? (
              <GalleryImage image={news.imageUrls} />
            ) : (
              <LazyLoadImage
                src={news.imageUrls[0]}
                alt={news.title}
                className="w-full"
              />
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center h-48 bg-gray-200">
            <p>Sem imagem</p>
          </div>
        )}
      </div>
      <div className="w-1/2">
        <h2 className="text-xl font-semibold mb-2">{news.title}</h2>
        <p className="text-gray-600 mb-1">{news.date}</p>
        <div className="mt-4">
          <div dangerouslySetInnerHTML={{ __html: news.content }} />
        </div>
        <div className="flex space-x-2 mt-2">
          <button
            className="bg-real-color text-white px-4 py-2 rounded-md"
            onClick={() => {
              window.open(news?.link);
              console.log("Ler mais");
            }}
          >
            Ler mais
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsArticle;
