import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Browser from "./Browser";
import Mobile from "./Mobile/MobileComponent";
import Header from "./components/newComponents/header";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import whatsapp from "./assets/img/whatsapp.webp";
import backgroundimage from "./assets/conceito/1.webp";
import HeaderMobile from "./components/Mobile/HeaderMobile";
import VideoLoader from "./components/VidelLoader";
import { whatsAppInfo } from "./api/search";
interface WhatsAppSets {
  id: number;
  number: string,
  statusMessage: string,
  chatMessage : string,
  darkmode : boolean,
  Abertura: string,
  Fecho: string,
  diasAbertos: string[],
}
function App() {
  const [loadingGallery, setLoadingGallery] = useState(true);
  // get current datetime if day of week show whatsapp, else dont, if between 8 and 18 show whatsapp else dont
  
  const [item,setItem] = useState<WhatsAppSets>();
  
  useEffect(() => {

    whatsAppInfo().then((data) => {
      setItem(data as any);
    });
  }
  ,[]);

  // item.abertura is string and fecho to turn it to number
  let abertura = parseInt(item?.Abertura as any) || 8;
  const fecho = parseInt(item?.Fecho as any) || 18;

  // check if abertura is bigger then fecho if it is just put the 8
  if (abertura > fecho) {
    abertura = 7;
  }

  const date = new Date();
  const hour = date.getHours();
  const phoneNumber = "351" + item?.number as any || "+351 914272606";
  const isDayTime = hour >= abertura && hour <= fecho
  const statusMessage = item?.statusMessage || "Estamos disponíveis"
  const chatMessage = item?.chatMessage || "escreva a sua mensagem..."
  const darkMode = item?.darkmode
  const daysOfWeek = ['Domingo', 'Segunda', 'Terca', 'Quarta', 'Quinta', 'Sexta', 'Sabado'];
  const currentDay = daysOfWeek[date.getDay()];

  const isOpen = item?.diasAbertos.includes(currentDay);



  // make timeout to load
  setTimeout(() => {
    setLoadingGallery(false);
  }, 1000);

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        letterSpacing: "normal",
      }}
    >
      {loadingGallery ? (
        <div className="items-center justify-center w-full h-full flex">
          <div
            className="items-center justify-center w-full h-full flex blur-background z-20"
            style={{
              backgroundImage: `url(${backgroundimage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-real-color border-solid z-50"></div>
          </div>
        </div>
      ) : (
        <div>
          <div className="font-nexa w-full h-full max-h-full max-w-full overflow-x-hidden">
            <div>{isMobile ? <HeaderMobile /> : <Header />}</div>
            <div id="galeria" className="w-full">
              {/* div website content */}
              <div className="flex w-full">
                <div className="w-full h-full">
                  {/* div Carousel*/}
                  <VideoLoader active={true} />
                </div>
              </div>
            </div>
            {isMobile ? <Mobile /> : <Browser />}
          </div>
        </div>
      )}
      <div
        className="fixed z-50"
        style={{
          bottom: "10px",
          right: "10px",
        }}
      >
        {isDayTime && isOpen ? (
        <FloatingWhatsApp
          phoneNumber={phoneNumber}
          accountName="Real Business Center"
          avatar={whatsapp}
          darkMode={darkMode}
          statusMessage={statusMessage}
          chatMessage={chatMessage}
          placeholder="Colocar uma questão..."
        />
        ) : (
          <></>
        )}
        
      </div>
    </div>
  );
}
export default App;
