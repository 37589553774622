import axios from "axios";

// set API_URL and token
const API_URL = "https://strapi.realbusinesscenter.wt.pt/api";
const UPLOAD_URL = "https://strapi.realbusinesscenter.wt.pt";

const token = process.env.REACT_APP_API_TOKEN;

export async function fetchPlantasData() {
  try {
    const response = await axios.get(`${API_URL}/plantas?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data.data; // Extract the "data" array from the response
    const plantasList = data.map((item) => {
      const attributes = item.attributes;
      const pdfPlanta =
        `${UPLOAD_URL}` + attributes.pdf_planta?.data?.attributes?.url || null;

      return {
        id: item.id,
        title: attributes.title,
        componente: attributes.componente,
        area: attributes.area,
        planta_file_url: pdfPlanta,
      };
    });

    return plantasList;
  } catch (error) {
    console.log(error);
    return []; // Return an empty array in case of error
  }
}

// fetch edificos
export async function fetchEdificiosData() {
  try {
    const response = await axios.get(`${API_URL}/escritorios?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data.data; // Extract the "data" array from the response
    const edificiosList = data.map((item) => {
      const attributes = item.attributes;
      // attributes imagem é um array, guardar em um array da seguinte forma `${UPLOAD_URL}`+ attributes.imagem?.data?.attributes?.url || null;
      // Check if "imagem" attribute exists and is an array
      if (attributes && Array.isArray(attributes.imagem?.data)) {
        // Create an array of URLs by processing each image in the array
        const imageUrls = attributes.imagem?.data.map((image) => {
          const imageUrl = `${UPLOAD_URL}${image?.attributes?.url}` || null;
          return imageUrl;
        });
        return {
          id: item.id,
          title: attributes.title,
          componente: attributes.componente,
          imageUrls: imageUrls,
        };
      }
      return {
        id: item.id,
        title: attributes.title,
        componente: attributes.componente,
        imageUrls: [],
      };
    });
    return edificiosList;
  } catch (error) {
    console.log(error);
    return []; // Return an empty array in case of error
  }
}

// search by id
export async function fetchPlantasDataById(id) {
  if (!id) {
    return [];
  }
  try {
    const response = await axios.get(`${API_URL}/plantas/${id}?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data.data; // Extract the "data" array from the response
    const attributes = data.attributes;
    const pdfPlanta =
      `${UPLOAD_URL}` + attributes.pdf_planta?.data?.attributes?.url || null;

    const planta = {
      id: data.id,
      title: attributes.title,
      componente: attributes.componente,
      area: attributes.area,
      planta_file_url: pdfPlanta,
    };

    return planta;
  } catch (error) {
    console.log(error);
    return []; // Return an empty array in case of error
  }
}
//fetch edificio by id

export async function fetchEdificiosDataById(id) {
  if (!id) {
    return [];
  }
  try {
    const response = await axios.get(
      `${API_URL}/escritorios/${id}?populate=*`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data.data; // Extract the "data" array from the response
    const attributes = data.attributes;
    // get imagem is array
    const imageUrls = attributes.imagem?.data.map((image) => {
      const imageUrl = `${UPLOAD_URL}${image?.attributes?.url}` || null;
      return imageUrl;
    });
    const edificio = {
      id: data.id,
      title: attributes.title,
      componente: attributes.componente,
      imageUrls: imageUrls,
    };
    return edificio;
  } catch (error) {
    console.log(error);
    return []; // Return an empty array in case of error
  }
}

// fetch o-conceito, return data.attributes title and texto is not array
export async function fetchOConceitoData() {
  try {
    const response = await axios.get(`${API_URL}/o-conceito?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data.data; // Extract the "data" array from the response
    const attributes = data.attributes;
    // get imagem is array
    const imageUrls = attributes.imagem?.data.map((image) => {
      const imageUrl = `${UPLOAD_URL}${image?.attributes?.url}` || null;
      return imageUrl;
    });
    return {
      id: data.id,
      title: attributes.Title,
      texto: attributes.texto,
      imageUrls: imageUrls,
    };
  } catch (error) {
    console.log(error);
    return []; // Return an empty array in case of error
  }
}

// fetch localizacao
export async function fetchLocalizacaoData() {
  try {
    const response = await axios.get(`${API_URL}/localizacao?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data.data; // Extract the "data" array from the response
    const attributes = data.attributes;
    // get imagem is array
    const imageUrls = attributes.imagem?.data.map((image) => {
      const imageUrl = `${UPLOAD_URL}${image?.attributes?.url}` || null;
      return imageUrl;
    });
    return {
      id: data.id,
      title1: attributes.title1,
      texto1: attributes.texto1,
      title2: attributes.title2,
      texto2: attributes.texto2,
      title3: attributes.title3,
      texto3: attributes.texto3,
      title4: attributes.title4,
      texto4: attributes.texto4,
      imageUrls: imageUrls,
    };
  } catch (error) {
    console.log(error);
    return []; // Return an empty array in case of error
  }
}

// fetch contactos each one as title texto
export async function fetchContactosData() {
  try {
    const response = await axios.get(`${API_URL}/contactos?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data.data; // Extract the "data" array from the response
    const contactList = data.map((item) => {
      const attributes = item.attributes;
      return {
        id: item.id,
        title: attributes.title,
        text: attributes.texto,
      };
    });
    return contactList;
  } catch (error) {
    console.log(error);
    return []; // Return an empty array in case of error
  }
}


//fetch noticias

export async function fetchNoticiasData() {
  try {
    const response = await axios.get(`${API_URL}/noticias?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data.data;
    const noticiasList = data.map((item) => {
      const attributes = item.attributes;
      const imageUrls = attributes.galeria?.data.map((image) => {
        const imageUrl = `${UPLOAD_URL}${image?.attributes?.url}` || null;
        return imageUrl;
      });
      
      // Format the date to "dd mm yyyy"
      const rawDate = new Date(attributes.date);
      const formattedDate = `${rawDate.getDate()} de ${getMonthName(rawDate.getMonth())} ${rawDate.getFullYear()}`;
      
      return {
        id: item.id,
        title: attributes.title,
        date: formattedDate,
        content: attributes.content,
        imageUrls: imageUrls,
        link: attributes.link
      };
    });
    return noticiasList;
  } catch (error) {
    console.log(error);
    return []; // Return an empty array in case of error
  }
}

// Helper function to get the month name
function getMonthName(monthIndex) {
  const monthNames = [
    "jan", "fev", "mar", "abr", "mai", "jun",
    "jul", "ago", "set", "out", "nov", "dez"
  ];
  return monthNames[monthIndex];
}

// Get whatssapp info from api
export async function whatsAppInfo(){
  try 
  {
    const response = await axios.get(`${API_URL}/whatsapp`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    const data = response.data.data; // Extract the "data" array from the response
    const attributes = data.attributes;
    // para saber em que dias da semana estão abertos, é uma lista dos sete dias em boolean em falso ou verdadeiro, faz um array só com os dias que estão em verdadeiro (attribute.<dia da semana>)
    const diasAbertos = [];
    if (attributes.Segunda) diasAbertos.push('Segunda');
    if (attributes.Terca) diasAbertos.push('Terca');
    if (attributes.Quarta) diasAbertos.push('Quarta');
    if (attributes.Quinta) diasAbertos.push('Quinta');
    if (attributes.Sexta) diasAbertos.push('Sexta');
    if (attributes.Sabado) diasAbertos.push('Sabado');
    if (attributes.Domingo) diasAbertos.push('Domingo');

    return {
      number: attributes.NumeroWhatsApp,
      statusMessage: attributes.StatusMessage,
      chatMessage : attributes.chatMessage,
      darkmode : attributes.darkmode,
      Abertura: attributes.HoraDeAbertura,
      Fecho: attributes.HoraDeFecho,
      diasAbertos: diasAbertos
    };
  }
  catch (error)
  {
    console.log(error);
    return [];
  }
}
