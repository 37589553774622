import { Carousel } from "@material-tailwind/react";
import "../assets/css/gallery.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState, useEffect } from "react";

function GalleryImage({ image }: any) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Random second between 1 and 3 to load the gallery
    const randomSecond = Math.floor(Math.random() * 3) + 1;

    // Set a timeout to mark loading as false after randomSecond seconds
    const timeout = setTimeout(() => {
      setLoading(false);
    }, randomSecond * 1000);

    // Cleanup the timeout when the component unmounts
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-full w-full">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <Carousel
          className="carousel-container overflow-hidden"
          transition={{ duration: 2 }}
          autoplay={true}
          autoplayDelay={3500}
          loop={true}
        >
          {/* map images */}
          {image.map((img: any, index: any) => (
            <div className="relative h-full w-full" key={index}>
              <LazyLoadImage
                delayTime={300}
                key={index}
                effect="blur" // Optional: Add some visual effects during loading
                src={img}
                alt="gallery"
                height="100%" // Adjust this value according to your design and preference
                width="100%" // Adjust this value according to your design and preference
                style={{
                  paddingLeft: '5px',
                  paddingRight: '5px'
                }}
              />
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
}

export default GalleryImage;
