import { fetchOConceitoData } from "../../api/search";
import GalleryImage from "../../components/GalleryImage";
import React, { useEffect, useState } from "react";

interface conceito {
  id: number;
  title: string;
  texto: any;
  imageUrls: string[];
}
function MobileConceito() {


    const [items, setItems] = useState<conceito>();
    const [loadingGallery, setLoadingGallery] = useState(true);
  
    useEffect(() => {
      setLoadingGallery(true);
      fetchOConceitoData().then((data) => {
        setItems(data as any);
        setLoadingGallery(false);
      });
    }, []);
  
    const hasImages = (items: conceito): boolean => {
      if (items.imageUrls.length === 0) {
        return false;
      } else {
        return true;
      }
    };

    
  return (
    <div id="conceito">
      <div className="flex w-full mt-24">
        <div className="w-full h-full">
          <div className="flex flex-col items-center justify-center w-full h-full">
            <h1 className=" text-real-color text-titles-real font-bold uppercase">
              O Conceito
            </h1>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-6">
        <div className="w-full p-4">
          <div className="font-bold text-under-real text-default-real">
          <p className=" font-bold ">{items?.title}</p>
          </div>
          <div className="flex justify-center">
          <div
              className="text-default-light text-xl tracking-normal"
              dangerouslySetInnerHTML={{ __html: items?.texto }}
            />
          </div>
        </div>
      </div>
      {items ? (
          <>
            {hasImages(items) ? (
                <div className="flex justify-between mt-6">
                    <div className="w-full ">
                        <div className="flex justify-center p-4">
                        {loadingGallery ? (
                      <div className="flex items-center justify-center">
                        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-real-color border-solid"></div>
                      </div>
                    ) : (
                        <div className="flex">
                        {/* check for the ammount of images and if more then 1 load as gallery */}
                        {items?.imageUrls?.length > 1 ? (
                          <GalleryImage image={items?.imageUrls} />
                        ) : (
                          <img
                            src={items?.imageUrls[0]}
                            alt={items?.title}
                            className="w-full"
                          />
                        )}
                      </div>
                    )}
                        </div>
                    </div>
                </div>
            ) : null }
            </>
            ) : null }
    </div>
    );
}

export default MobileConceito;