import { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./assets/css/app.css";
import ItemsConheca from "./components/TextoComponent";
import PlantasSection from "./components/PlantasComponent";
import Contactos from "./components/newComponents/Contactos";
import TableComponent from "./components/tabela";
import Conceito from "./components/newComponents/Conceito";
import Localizacao from "./components/newComponents/Localizacao";
import Footer from "./components/newComponents/Footer";

function Browser() {
  // detect screensize
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const maxWidth = screenSize > 1920 ? "1900px" : "100%";
  const centerContentClass = screenSize > 1920 ? "mx-auto text-center" : "";

  return (
    <div
      className={`flex flex-col items-center w-full ${centerContentClass}`}
      style={{
        maxWidth,
      }}
    >
      <div className=" p-12"
        id="espaco_esritorios"
      >
        <div className="w-full p-12">
          <TableComponent />
        </div>

        {/* div with margin top to header */}
        <Conceito />
        
        <div id="espaco" className="mt-48">
          <div className="flex w-full mt-12">
            <div className="w-full h-full">
              <div className="flex flex-col items-center justify-center w-full h-full">
                <h1 className="text-real-color text-titles-real uppercase tracking-wide">
                  Conheça o Real Business Center
                </h1>
              </div>
            </div>
          </div>
          <ItemsConheca />
        </div>

        {/* div plantas */}
        <PlantasSection />
        <div id="localizacao" className="mt-48">
         
         <Localizacao />

         <Contactos />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Browser;