import { useEffect, useState } from "react";
import RLogo from "../../assets/img/RBC.webp";
import { useNavigate } from "react-router-dom";
function Header({ onConceitoClick }: any) {

  const navigate = useNavigate();
  const [showMenu] = useState(false);
  
  // check current link, if on noticias then buttons should all goto link/#name

  let currentPage: string;
  if (typeof window !== "undefined") {
    currentPage = window.location.pathname;
  }
  else {
    currentPage = "";
  }
  
  // if on noticias then buttons should all goto link/#name
  const onLinkClick = (link: string) => {
    if (currentPage === "/noticias") {
      window.location.href = `/#${link}`
    } else {
      if (link === "noticias"){
        navigate(`/${link}`)
      }
      else {
      window.location.href = `/#${link}`

      }
    }
  };


  // detect screensize
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // if screen size > 1900 width make the max width 1900 of all inside
  const maxWidth = screenSize > 1900 ? "1900px" : "100%";
  const centerContentClass = screenSize > 1900 ? "mx-auto text-center" : "";

  return (
    
      <div className="header-container flex flex-row justify-between items-center bg-transparent/10 absolute top-0 left-0 w-full z-50">
        <div
          className={`flex flex-row justify-between items-center w-full ${centerContentClass}`}
          style={{ maxWidth }}
        >
          <div className="ml-20 p-4">
            <a href="/">
            <img src={RLogo} alt="logo" className="mr-2 h-20" />
            </a>
          </div>

          <div
            className={`hidden md:flex flex-row justify-end items-center ${
              showMenu ? "opacity-0" : "opacity-100"
            }`}
          >
            <button
              className="text-white/90 text-xl mr-24 hover:text-default-light hover:cursor-pointer"
              onClick={() => onLinkClick("conceito")}
            >
              Conceito
            </button>

            <button
              className="text-white/90 text-xl mr-24 hover:text-default-light hover:cursor-pointer"
              onClick={() => onLinkClick("espaco")}
            >
              Espaço
            </button>
            <button
              className="text-white/90 text-xl mr-24 hover:text-default-light hover:cursor-pointer"
              onClick={() => onLinkClick("plantas")}
            >
              Plantas
            </button>
            <button
              className="text-white/90 text-xl mr-24 hover:text-default-light hover:cursor-pointer"
              onClick={() => onLinkClick("localizacao")}
            >
              Localização e contactos
            </button><button
              className="text-white/90 text-xl mr-24 hover:text-default-light hover:cursor-pointer"
              onClick={() => onLinkClick("noticias")}
            >
              Notícias
            </button>
          </div>
        </div>
      </div>
      
  );
}

export default Header;
