import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import RLogo from "../../assets/img/RBC.webp";
import "../../assets/css/mobileheader.css";

function HeaderMobile() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    // Add a class to the body when the menu is open to disable scrolling
    if (showMenu) {
      document.body.classList.add("mobile-header-menu-open");
    } else {
      document.body.classList.remove("mobile-header-menu-open");
    }
  }, [showMenu]);

  return (
    <div>
      <div className="mobile-header-container flex flex-row justify-between items-center w-full z-50">
        <img src={RLogo} alt="logo" className="mobile-header-logo ml-4 p-2 h-10" />

        {/* Responsive hamburger menu */}
        <div className="flex flex-row items-center mr-4">
          <button
            onClick={toggleMenu}
            className="text-default-real text-sm mr-4 hover:text-default-light hover:cursor-pointer"
          >
            {showMenu ? (
              <AiOutlineClose size={42} />
            ) : (
              <GiHamburgerMenu size={42} />
            )}
          </button>
        </div>
      </div>

      {/* Responsive menu content */}
      {showMenu && (
        <div className="md:hidden mobile-header-menu bg-gray-50 py-2 z-10">
          <a
            href="#conceito"
            className={`text-default-real text-sm mb-2 ml-4 hover:text-default-light hover:cursor-pointer`}
          >
            Conceito
          </a>
          <a
            href="#espaco"
            className="text-default-real text-sm mb-2 ml-4 hover:text-default-light hover:cursor-pointer"
          >
            Espaço
          </a>
          <a
            href="#plantas"
            className="text-default-real text-sm mb-2 ml-4 hover:text-default-light hover:cursor-pointer"
          >
            Plantas
          </a>
          <a
            href="#localizacao"
            className="text-default-real text-sm mb-2 ml-4 hover:text-default-light hover:cursor-pointer"
          >
            Localização e contactos
          </a>
          <a
            href="/noticias"
            className="text-default-real text-sm mb-2 ml-4 hover:text-default-light hover:cursor-pointer"
          >
            Notícias
          </a>
        </div>
      )}

      {/* Add an empty div to push the rest of the page down when the menu is open */}
      {showMenu && <div className="w-full h-14"></div>}
    </div>
  );
}

export default HeaderMobile;
