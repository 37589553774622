
import comercial from "../../assets/img/office.svg";
import svgForSecondSquare from "../../assets/img/shop.svg";
import svgForThirdSquare from "../../assets/img/lounge.svg";
import svgForFourthSquare from "../../assets/img/multi.svg";function MobileTabela() {
  return (
    <div id="informacao" className="mt-24">
      <div className="w-full p-12 flex items-center relative text-real-color h-72">
        <div className="w-1/2 flex h-full mr-4">
          <div className="w-full h-full flex justify-center items-center relative">
            <img
              src={comercial}
              alt=""
              width={"150px"}
              height={"auto"}
              className="object-contain object-right"
            />
          </div>
        </div>

        <div className="border-l-2 border-real-color w-[2px] h-1/2"></div>

        {/* Text div */}
        <div className="w-1/2 h-full flex justify-center items-center ml-4">
          <div className="w-[150px] h-full flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              <div className="text-real-color text-titles-real uppercase tracking-wide">
                <p>37</p>
              </div>
              <div className="text-subtitles-of-real text-center font-bold">
                <p>Escritórios</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full p-12 flex items-center relative text-real-color h-72">
        <div className="w-1/2 flex h-full mr-4">
          <div className="w-full h-full flex justify-center items-center relative">
            <img
              src={svgForThirdSquare}
              alt=""
              width={"150px"}
              height={"auto"}
              className="object-contain object-right"
            />
          </div>
        </div>

        <div className="border-l-2 border-real-color w-[2px] h-1/2"></div>

        {/* Text div */}
        <div className="w-1/2 h-full flex justify-center items-center ml-4">
          <div className="w-[150px] h-full flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              <div className="text-real-color text-titles-real uppercase tracking-wide">
                <p>200m²</p>
              </div>
              <div className="text-subtitles-of-real text-center font-bold">
                <p>Área Lounge</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full p-12 flex items-center relative text-real-color h-72">
        <div className="w-1/2 flex h-full mr-4">
          <div className="w-full h-full flex justify-center items-center relative">
            <img
              src={svgForSecondSquare}
              alt=""
              width={"150px"}
              height={"auto"}
              className="object-contain object-right"
            />
          </div>
        </div>
        <div className="border-l-2 border-real-color w-[2px] h-1/2"></div>

        {/* Text div */}
        <div className="w-1/2 h-full flex justify-center items-center ml-4">
          <div className="w-[150px] h-full flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              <div className="text-real-color text-titles-real uppercase tracking-wide">
                <p>4</p>
              </div>
              <div className="text-subtitles-of-real text-center font-bold">
                <p>Espaços Comerciais</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full p-12 flex items-center relative text-real-color h-72">
        <div className="w-1/2 flex h-full mr-4">
          <div className="w-full h-full flex justify-center items-center relative">
            <img
              src={svgForFourthSquare}
              alt=""
              width={"150px"}
              height={"auto"}
              className="object-contain object-right"
            />
          </div>
        </div>

        <div className="border-l-2 border-real-color w-[2px] h-1/2"></div>

        {/* Text div */}
        <div className="w-1/2 h-full flex justify-center items-center ml-4">
          <div className="w-[150px] h-full flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              <div className="text-real-color text-titles-real uppercase tracking-wide">
                <p>1</p>
              </div>
              <div className="text-subtitles-of-real text-center font-bold">
                <p>Espaço Multiusos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileTabela;
