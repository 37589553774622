import React, { useEffect, useState } from "react";
import { fetchContactosData } from "../../api/search";
import ContactForm from "../../components/formulario";
interface contacts {
  id: number;
  title: string;
  text: any;
}
function MobileContactForm() {
  const [items, setItems] = useState<contacts[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchContactosData().then((data) => {
      setItems(data as any);
      setLoading(false);
    });
  }, []);

  return (
    <div id="Contactos" className="mt-12">
      <div className="flex w-full mt-12">
        <div className="w-full h-full">
          <div className="flex flex-col items-center justify-center w-full h-full">
            <h1 className="text-center text-real-color text-titles-real font-bold">
              Contactos
            </h1>
          </div>
        </div>
      </div>
      <div className="flex w-full mt-12 relative">
        <div className="w-full flex flex-col">
          <div className="mt-12 w-full text-left">
            <p className="font-bold text-default-real text-left text-subtitles-of-real mb-6">
              Real Business Center
            </p>
            <div id="apoio" className="mt-12">
              {/* map items  */}
              {items.map((item) => (
                <div key={item.id}>
                  <p className=" text-subtitles-of-real text-real-color">
                    {item.title}
                  </p>
                  <div className="text-default-light tracking-normal">
                    <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-12 flex relative h-full">
          <div className="flex flex-col items-center justify-center w-full h-full">
            <ContactForm />
          </div>
        </div>
    </div>
  );
}
export default MobileContactForm;
