import React, { useEffect, useState } from "react";
import ContactForm from "../formulario";
import { fetchContactosData } from "../../api/search";
interface contacts {
  id: number;
  title: string;
  text: any;
}

function Contactos() {
  const [items, setItems] = useState<contacts[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchContactosData().then((data) => {
      setItems(data as any);
      setLoading(false);
    });
  }, []);

  return (
    <div id="Contactos" className="mt-12">
      <div className="flex w-full mt-12">
        <div className="w-full h-full">
          <div className="flex flex-col items-center justify-center w-full h-full">
            <h1 className="text-real-color text-titles-real uppercase tracking-wide">
              Contactos
            </h1>
          </div>
        </div>
      </div>
      <div className="flex w-full mt-12 relative">
        <div className="w-1/2 flex flex-col p-12 text-left justify-center h-full">
          {/* aqui estão os contactos */}
          <div id="realvida">
            <p className="text-titles-real text-real-color">
              Real Business Center
            </p>
          </div>
          <div id="apoio" className="mt-12">
            {/* map items  */}
            {items.map((item) => (
              <div key={item.id}>
                 <p className=" text-subtitles-of-real text-real-color">
                  {item.title}
                </p>
                <div className="text-default-light tracking-normal">
                  <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-1/2 m-4 flex relative h-full">
          <div className="flex flex-col items-center justify-center w-full h-full p-12">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactos;
